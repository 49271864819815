import React from 'react';
import NavAccount from './NavAccount';
function AcountStaking(props) {
    return (
        <>
            <div className='wapper-account'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-3'>
                            <NavAccount />
                        </div>
                        <div className='col-md-9'>
                            <main>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2">Staking</h1>
                                </div>

                                <div className='tabc'>
                                    <h4>Coming soon</h4>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AcountStaking;