import React, { useEffect, useState } from 'react';
import { getWalletTransfer, convertPoints } from '../../../services/wallet';
import { convertDate, convertNumber } from '../../../setup/fc';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {setAuth} from '../../../redux/slices/authSlice';
import { setPoint } from '../../../redux/slices/pointSlice';
import { setPointCoin } from '../../../redux/slices/pointCoinSlice';
function Transfer(props) {

    const [transaction, setTransaction] = useState([]);
    const [withdrawal, setWithdrawal] = useState(0);
    const [typePay, setTypePay] = useState(1);
    const [ratio, setRatio] = useState(0);
    const [amount, setAmount] = useState(0);
    const [withdrawal1, setWithdrawal1] = useState(0);
    const [withdrawal2, setWithdrawal2] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const dispatch = useDispatch();
    const point = useSelector((state) => state.point);
    const pointCoin = useSelector((state) => state.pointCoin);

    useEffect(() => {
        loadDataWallet();
    }, []);

    const loadDataWallet = async () => {
        const dataWallet = await getWalletTransfer();
      
        if (dataWallet) {
            setWithdrawal(dataWallet.point);
            localStorage.setItem('point', dataWallet.point);
            localStorage.setItem('pointCoin', dataWallet.pointCoin);
            setTransaction(dataWallet.listWallet);           
            setRatio(dataWallet.ratio);
        }

    }

    const handleWithdrawal = async () => {
        if (isLoading == 1) {
            return true;
        }
        if (Number(amount) <= 0) {
            toast.error('Please enter the amount to transfer');
            return true;
        }
      
        setIsLoading(1);
        const dataWallet = await convertPoints(amount, typePay);
       
        if (dataWallet && dataWallet.status == 999) {
            setWithdrawal(dataWallet.point);
            localStorage.setItem('point', dataWallet.point);
            localStorage.setItem('pointCoin', dataWallet.pointCoin);
            dispatch(setPoint(dataWallet.point));
            dispatch(setPointCoin(dataWallet.pointCoin));

            setTransaction(dataWallet.listWallet);
            toast.success('Conversion successful');
        } else if (dataWallet && dataWallet.status == 771) {
            toast.error('Insufficient points');
        }else if (dataWallet && dataWallet.status == 772) {
            toast.error('Insufficient WKS number.');
        } else {
            toast.error('Withdrawal request failed');
        }

        setIsLoading(0);

    }
    const changeAmount = (e) => {

        let val = e.target.value;

        if(typePay == 1){
            if (Number(val) > withdrawal) {
                setAmount(withdrawal);
                toast.error('You are allowed to withdraw up to ' + withdrawal);
                return true;
            }
        }else{
            if (Number(val) > localStorage.getItem('pointCoin')) {
                setAmount(localStorage.getItem('pointCoin'));
                toast.error('You are allowed to withdraw up to ' + localStorage.getItem('pointCoin'));
                return true;
            }
        }
        
        setAmount(val);

    }
    return (
        <>
        <div className="row">
            <div className='col-md-12'>
                <div className='withdraw-money tabc'>
                    <h3>Transfer</h3>
                    <p className="text-tb">The conversion rate is 1 WKS = {ratio} points.</p>
                    <div className="mb-3">                        
                        <label htmlFor="ipwallet" className="form-label">Quantity ({ typePay == 1 ? convertNumber(localStorage.getItem('point')) : convertNumber(localStorage.getItem('pointCoin')) })</label>
                        <input type="number" className="form-control" id="ipwallet" placeholder="Withdrawal amount" value={amount} onChange={(e) => changeAmount(e)} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ipwallet" className="form-label">Type</label>
                        <select className="form-select" onChange={(event) => setTypePay(event.target.value)}>
                            <option value="1">Point to WKS</option>
                            <option value="2">WKS to Point</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ipwallet" className="form-label">You receive</label>
                        <input type="text" className="form-control" id="ipwallet" placeholder="Withdrawal amount" readOnly={true} value={typePay == 1 ? (amount / ratio) : (amount * ratio)} />
                    </div>

                    {
                        isLoading == '0' ? (<>
                            <button className="btn btn-primary py-2" type="button" onClick={() => handleWithdrawal()}>Convert</button>
                        </>) : (<>
                            <button className="btn btn-primary py-2" type="button">Processing</button>
                        </>)

                    }

                </div>
            </div>
            <div className='tabc'>
                                    <table className='table tablec'>
                                        <thead>
                                            <tr>                                               
                                                <th>Quantity</th>
                                                <th>Type</th>
                                                <th>You receive</th>                                               
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                transaction && transaction.length !== undefined ? (
                                                    transaction.map((item, index) => (
                                                        <>
                                                            <tr>                                                              
                                                                <td >{convertNumber(item.qty)}</td>
                                                                <td>{item.type == 1 ? (
                                                                    <> <strong className='text-warning'>Point to WKS</strong></>
                                                                ) : (
                                                                    <><strong className='text-info'>WKS to Point</strong></>
                                                                )}</td>
                                                                <td>{convertNumber(item.receive)}</td> 
                                                                <td>{convertDate(item.createdAt)}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                ) : (<></>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
        </div>
        </>
    );
}

export default Transfer;
