import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import NavAccount from './NavAccount';
import { getProfile,postProfile,postProfilePass } from '../../services/profile';
import sounds, { stopAllSounds } from './Game/audioManager';
// import './Account.css';
function AccountAffiliate(props) {

    const [email, setEmail] = useState("");
    const [userLogin, setUserLogin] = useState("");

    const [oldPass, setoldPass] = useState("");
    const [newPass, setnewPass] = useState("");

    const [wallet, setWallet] = useState("");
    const [displayName, setdisplayName] = useState("");

    const isValidPass = () => {
        let check = 0;      

        if (!oldPass) {
            toast.error('Please enter your current password');
            check = 1;
        }

        if (!newPass) {
            toast.error('Please enter a new password');
           check = 1;
        }

        const validPasswordRegex = /^.{8,32}$/;
        if(!validPasswordRegex.test(newPass)){
            toast.error('Please enter a valid password (8 to 32 characters)');
            return false;
        }

        if(check == 1){
            return false;
        }

        return true;
    }
    const handleChangPass = async() => {
        if(isValidPass()){
            const profile = await postProfilePass(oldPass,newPass);

            if(profile.status == '999'){
                toast.success('Updated password successfully');
            }else{
                toast.error('Current password is incorrect');
            }
        }
    }

    const isValidProfile = () => {
        let check = 0;      

        if (!displayName) {
            toast.error('Full Name is required');
            check = 1;
        }

        if (!wallet) {
            toast.error('Wallet address is required');
           check = 1;
        }

        if(check == 1){
            return false;
        }

        return true;
    }
    const handleUpdateProfile = async() => {
       if(isValidProfile()){
            const profile = await postProfile(displayName,wallet);

            if(profile.status == '999'){
                toast.success('Success');
            }else{
                toast.error('Error');
            }
       }
    }
    
    useEffect( () => {
        loadProfile();
    },[])

    const loadProfile = async() => {
        stopAllSounds();
        const profile = await getProfile();       
        if(profile){
            setEmail(profile.email);
            setUserLogin(profile.userLogin);
            setWallet(profile.wallet);
            setdisplayName(profile.displayName);
           
        }
        
    }

   
    return (
        <>
            <div className='wapper-account'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-3'>
                            <NavAccount />
                        </div>
                        <div className='col-md-9'>
                            <main>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2">Profile</h1>
                                </div>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <div className='personal-info tabc'>
                                            <h3>User Profile</h3>
                                            <div className="mb-3">
                                                <label htmlFor="ipemail" className="form-label">Email</label>
                                                <input type="text" className="form-control" id="ipemail" placeholder="Email" value={email} disabled />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="userlogin" className="form-label">Username</label>
                                                <input type="text" className="form-control" id="userlogin" placeholder="Username" value={userLogin} disabled />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="displayName" className="form-label">Full Name</label>
                                                <input type="text" className="form-control" id="displayName" placeholder="Full Name" value={displayName} onChange={(event) => setdisplayName(event.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="ipwallet" className="form-label">Wallet address</label>
                                                <input type="text" className="form-control" id="ipwallet" placeholder="Wallet address" value={wallet} onChange={(event) => setWallet(event.target.value)} />
                                            </div>
                                            <button className="btn btn-primary py-2" type="button" onClick={() => handleUpdateProfile()}>Save</button>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='security tabc'>
                                            <h3>Change the password</h3>
                                            <div className="mb-3">
                                                <label htmlFor="ippass" className="form-label">Old password</label>
                                                <input type="password" className="form-control" id="ippass" placeholder="Old password" value={oldPass} onChange={(event) => setoldPass(event.target.value)} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="ipnewpass" className="form-label">New Password</label>
                                                <input type="password" className="form-control" id="ipnewpass" placeholder="New Password" value={newPass} onChange={(event) => setnewPass(event.target.value)} />
                                            </div>
                                            <button className="btn btn-primary py-2" type="button" onClick={() => handleChangPass()}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountAffiliate;