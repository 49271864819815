import React from 'react';

function About(props) {
    return (
        <>
            <div id="sc_about">
                <div id="sc_about-bg">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 px-4">
                                <div className="boxt-text-about">
                                    <h2 className='title-about'>What is <br></br>Wolf Kingdoms (WKS)?</h2>
                                    <div>
                                        <p>
                                            <strong>Wolf Kingdoms (WKS)</strong> is a new digital currency, designed to connect a community of enthusiasts who love the world of animation and video games through a unique and secure blockchain platform. Inspired by fairy tales and myths about wolves, WKS is not just a currency unit but also part of a vast kingdom where members can participate in unique activities, games, and events.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default About;