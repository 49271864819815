import cApi from '../setup/axios';
import { useNavigate } from 'react-router-dom';


const getDeposit = async () => {
    let userData = {};
    const rs = await cApi.post('api/v1/getdeposit', userData);   
    return rs.data;
}



export { getDeposit }