import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
const Footer = (props) => {

    const location = useLocation();

    let checkHome = 'home py-3 my-4';

    if (location.pathname != '/') {
        checkHome = 'viewPage py-3 my-4';
    }
    return (
        <>
            <footer className={checkHome}>
                <div className='container'> 
                    <p className="text-center ">© 2024 - Wolf Kingdoms</p>
                </div>
            </footer>
        </>
    );
}

export default Footer;