import React, { useEffect, useState } from 'react';
import { getWallet, payWithdrawal } from '../../../services/wallet';
import { convertDate, convertNumber } from '../../../setup/fc';
import { toast } from 'react-toastify';
function Withdraw() {

    const [transaction, setTransaction] = useState([]);
    const [withdrawal, setWithdrawal] = useState(0);
    const [typePay, setTypePay] = useState(2);
    const [ratio, setRatio] = useState(1);
    const [amount, setAmount] = useState(0);
    const [withdrawal1, setWithdrawal1] = useState(0);
    const [withdrawal2, setWithdrawal2] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [wallet, setWallet] = useState('');

    
    useEffect(() => {
        loadDataWallet();
    }, []);

    const loadDataWallet = async () => {
        const dataWallet = await getWallet();      
        if (dataWallet) {
            setWithdrawal(dataWallet.pointCoin);
            localStorage.setItem('point', dataWallet.point);
            localStorage.setItem('pointCoin', dataWallet.pointCoin);

            setWallet(dataWallet.wallet);
            setTransaction(dataWallet.listWallet);            
            setRatio(dataWallet.ratio);
        }

    }

    const handleWithdrawal = async () => {
        if (isLoading == 1) {
            return true;
        }
        if (Number(amount) <= 0) {
            toast.error('Please enter withdrawal amount.');
            return true;
        }
        if (Number(amount) > withdrawal) {
            setAmount(withdrawal);
            toast.error('You are allowed to withdraw up to ' + withdrawal);
            return true;
        }
       
        if(wallet == ''){
            toast.error('Please add receiving wallet address.');
            return true;
        }

        if(amount < 1000 ){
            toast.error('The minimum withdrawal amount is 1,000 WKS.');
            return true;
        }
        
        setIsLoading(1);
        const dataWallet = await payWithdrawal(amount);
        if (dataWallet && dataWallet.status == 999) {
            setWithdrawal(dataWallet.point);
            localStorage.setItem('point', dataWallet.point);
            localStorage.setItem('pointCoin', dataWallet.pointCoin);
            setTransaction(dataWallet.listWallet);
            toast.success('Your request will be processed within 24 hours');
        } else if (dataWallet && dataWallet.status == 888) {
            toast.error('The account does not have enough withdrawals');
        } else {
            toast.error('Withdrawal request failed');
        }

        setIsLoading(0);

    }
    const changeAmount = (e) => {

        let val = e.target.value;       
        setAmount(val);

    }
    return (
        <>
            <div className="row">               
                <div className='col-md-12'>
                    <div className='withdraw-money tabc'>
                        <h3>Withdraw</h3>
                        <p className="text-tb">The minimum withdrawal amount is 1,000 WKS.</p>
                        <div className="mb-3">
                            <label htmlFor="ipwallet" className="form-label">Quantity ({convertNumber(withdrawal)})</label>
                            <input type="number" className="form-control" id="ipwallet" placeholder="Withdrawal amount" value={amount} onChange={(e) => changeAmount(e)} />
                        </div>                       
                        <div className="mb-3">
                            <label htmlFor="ipwallet" className="form-label">You receive</label>
                            <input type="text" className="form-control" id="ipwallet" placeholder="You receive" readOnly={true} value={typePay == 1 ? (amount) : (amount * ratio)} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ipwallet" className="form-label">Receiving wallet</label>
                            <input type="text" className="form-control" id="ipwallet" placeholder="Receiving wallet" readOnly={true}  value={wallet}/>
                        </div>

                        {
                            isLoading == '0' ? (<>
                                <button className="btn btn-primary py-2" type="button" onClick={() => handleWithdrawal()}>Withdraw</button>
                            </>) : (<>
                                <button className="btn btn-primary py-2" type="button">Processing</button>
                            </>)

                        }

                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='tabc'>
                        <table className='table tablec'>
                            <thead>
                                <tr>                                  
                                    <th>Quantity</th>
                                    <th>Address</th>                                  
                                    <th>Status</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    transaction && transaction.length !== undefined ? (
                                        transaction.map((item, index) => (
                                            <>
                                                <tr>                                                 
                                                    <td >{convertNumber(item.coin)}</td>  
                                                    <td>{item.address}</td>
                                                    <td>{item.status === 2 ? (
                                                        <> <strong className='text-primary'>Complete</strong></>
                                                    ) : item.status === 1 ? (
                                                        <><strong className='text-danger'>Error</strong></>
                                                    ) : (
                                                        <><strong className='text-warning'>Pending</strong></>
                                                    )}</td>
                                                    <td>{convertDate(item.createdAt)}</td>
                                                </tr>
                                            </>
                                        ))
                                    ) : (<></>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Withdraw;
