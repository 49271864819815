import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    NavLink,
    useNavigate
} from "react-router-dom";
import { logoutUser } from '../../services/userService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAuth } from '../../redux/slices/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faShareNodes, faWallet, faUser, faRightFromBracket, faBolt, faCoins, faGamepad,faAngleDown,faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { convertNumber } from '../../setup/fc';
import { sendCoin } from "../../services/smartContract";
import { RewardsMembers } from "../../services/userService";



function NavAccount(props) {

    const [loading, setLoading] = useState(1);
    const [viewPointCoin, setViewPointCoin] = useState(0);
    const [viewPoint, setViewPoint] = useState(0);
    const [nameUser, setNameUser] = useState('');
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [menuMB, setMenuMB] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            await fc_start();
        };
        fetchData();
    }, []);

    const fc_start = async () => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        const tviewPoint = localStorage.getItem('point');
        let tPointCoin = localStorage.getItem('pointCoin');

        if (tPointCoin) {
            setViewPointCoin(tPointCoin);
        }

        if (tviewPoint) {
            setViewPoint(tviewPoint);
        }
      
        setNameUser(auth.user.displayName);
    }
    const handleUserLogout = async () => {

        let data = await logoutUser();

        let authData = {
            user: [],
            isAuthenticated: false,
            token: '',
            isError: false
        }

        toast.error('Log out success...');
        dispatch(logoutAuth());
        localStorage.removeItem('auth');

        navigate('/');
    }

    const handleButtonClickNAV = () => {
        setMenuMB(!menuMB);
      };

    const Withdrawal = async () => {
        let data = await sendCoin();
        console.log(data);
        if (!data || data.code || (data.status && data.status != true)) {
            toast.error("Error");
            setLoading(0);
        } else {
            toast.success("Withdraw coins successfully");
            localStorage.setItem('pointCoin', 0);
            setLoading(0);
            await RewardsMembers();
            setViewPointCoin(0);

        }
    }
    return (
        <>
            <div className='navAccount'>
                <div className='box-avatar'>
                    <div className='box-avatar-img'>
                        <img src='wks-avatar.png' className='avatar' />
                    </div>
                    <h4>{nameUser}</h4>
                    <span><FontAwesomeIcon icon={faBolt} /> {convertNumber(viewPoint)}</span>
                    <p><span><FontAwesomeIcon icon={faCoins} /> {convertNumber(viewPointCoin)} WKS</span></p>
                    {/* {loading == 1 && Number(viewPointCoin) >= 10000 ? (<>
                        <button type="button" className='btn-sendcoin' onClick={() => Withdrawal()}>Withdraw Coins</button>
                    </>) : (<></>)
                    } */}
                    
                </div>
                <ul className={menuMB ? 'active' : ''}>
                    <li><NavLink to="/account" className="nav-link px-2"><span><FontAwesomeIcon icon={faHouse} /></span> Dashboard</NavLink></li>
                    <li><NavLink to="/affiliate" className="nav-link px-2"><span><FontAwesomeIcon icon={faShareNodes} /></span> Reward</NavLink></li>
                    <li><NavLink to="/wallet" className="nav-link px-2"><span><FontAwesomeIcon icon={faWallet} /></span> Swap</NavLink></li>
                    <li><NavLink to="/game" className="nav-link px-2"><span><FontAwesomeIcon icon={faGamepad} /></span> Game</NavLink></li>
                    <li><NavLink to="/profile" className="nav-link px-2"><span><FontAwesomeIcon icon={faUser} /></span> Profile</NavLink></li>
                    <li><a href="#" className="nav-link px-2" onClick={() => handleUserLogout()}><span><FontAwesomeIcon icon={faRightFromBracket} /></span> Logout</a></li>
                </ul>
                <div className='text-center'>
                <button className="btn-view-nav-mb" onClick={handleButtonClickNAV}><FontAwesomeIcon icon={menuMB ? faAngleUp : faAngleDown } /></button>
                </div>
            </div>
        </>
    );
}

export default NavAccount;