import React, { useState, useEffect } from 'react';
import './CardWolf.css';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeXmark, faVolumeOff, faStar, faBolt, faArrowsUpDownLeftRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { getPlaygame, setGamePoint, userStartGame, setGameTurn, buyPlayGame, setGamePointOld } from "../../../services/gameService";
import sounds, { stopAllSounds } from './audioManager';
// Danh sách các ảnh giả định
const images = [
    '/images/wolf1.png',
    '/images/wolf2.png',
    '/images/wolf3.png',
    '/images/wolf4.png',
    '/images/wolf5.png',
    '/images/wolf6.png',
    '/images/wolf7.png',
    '/images/wolf8.png'
];

function CardWolf() {
    const [cards, setCards] = useState([]);
    const [flipped, setFlipped] = useState([]);
    const [matched, setMatched] = useState([]);
    const [score, setScore] = useState(0);
    const [timeLeft, setTimeLeft] = useState(120); // Thời gian cho vòng đầu tiên (2 phút)
    const [level, setLevel] = useState(1); // Vòng hiện tại
    const [gameOver, setGameOver] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false); // Trạng thái chơi
    const [levelCompleted, setLevelCompleted] = useState(false); // Trạng thái hoàn thành vòng
    const [playGame, setPlayGame] = useState(0);
    const [isMusicPlaying, setIsMusicPlaying] = useState(true);
    const [oldGame, setOldGame] = useState(0);

    useEffect(() => {

        loadPlayGame();
    }, []);

    const loadPlayGame = async () => {
        const data = await getPlaygame();
        if (data && data.playgame) {
            setPlayGame(data.playgame);
        }

    }

    useEffect(() => {
        const shuffledCards = [...images, ...images]
            .sort(() => Math.random() - 0.5)
            .map((image, index) => ({ id: index, image }));

        setCards(shuffledCards);
    }, [level]);

    useEffect(() => {
        if (timeLeft <= 0) {
            setGameOver(true);
            setIsPlaying(false);
            playGameOverSound();
            if (sounds && sounds.startSound) {
                sounds.startSound.stop();
            }
            setPointGameOver();
        }

        if (timeLeft > 0 && isPlaying && !gameOver && !levelCompleted) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [timeLeft, isPlaying, gameOver, levelCompleted]);

    const setPointGameOver = async () => {
        if (Number(score) > 0) {
            if (oldGame == 0) {

                let endgame = await setGamePoint(score, level);
                if (endgame.status == '999') {
                    setOldGame(endgame.id);
                }
            } else {
                await setGamePointOld(score, level, oldGame);

            }
        }

    }
    const playBtnOverSound = () => {
        if (sounds && sounds.btnOverSound && isMusicPlaying) {
            sounds.btnOverSound.play();
        }
    };
    const playCoinSound = () => {
        if (sounds && sounds.coinsSound && isMusicPlaying) {
            sounds.coinsSound.play();
        }
    };
    const playWinSound = () => {
        if (sounds && sounds.winSound && isMusicPlaying) {
            sounds.winSound.play();
        }
    };
    const playGameOverSound = () => {
        if (sounds && sounds.gameOverSound && isMusicPlaying) {
            sounds.gameOverSound.play();
        }
    };
    const handleFlip = (index) => {
        if (flipped.length === 2 || flipped.includes(index) || matched.includes(index) || gameOver || levelCompleted) {
            return;
        }
        playBtnOverSound();
        const newFlipped = [...flipped, index];
        setFlipped(newFlipped);

        if (newFlipped.length === 2) {
            const [firstIndex, secondIndex] = newFlipped;
            if (cards[firstIndex].image === cards[secondIndex].image) {
                setMatched([...matched, firstIndex, secondIndex]);
                setScore((prevScore) => prevScore + 5);
                setFlipped([]);
                playCoinSound();
                if (matched.length + 2 === cards.length) {
                    playWinSound();
                    setLevelCompleted(true);
                    setIsPlaying(false);
                }
            } else {
                setTimeout(() => setFlipped([]), 1000);
            }
        }
    };

    const handleStartGame = async () => {
        setIsPlaying(true);
        setGameOver(false);
        setLevelCompleted(false);
        setTimeLeft(120);
        setScore(0);
        setMatched([]);
        setFlipped([]);
        setLevel(1);
        playStartSound();
        setOldGame(0);
        await userStartGame();
    };

    const handleNextLevel = () => {
        setLevel((prevLevel) => prevLevel + 1);
        setTimeLeft(120 - (level * 15));        
        setMatched([]);
        setFlipped([]);
        setLevelCompleted(false);
        setIsPlaying(true);
    };

    const playStartSound = () => {
        if (sounds && sounds.startSound && isMusicPlaying) {
            sounds.startSound.play();
        }
    };

    const restartGame = async () => {
        setIsPlaying(false);
        setGameOver(false);
        setLevelCompleted(false);
        setTimeLeft(120);
        setScore(0);
        setMatched([]);
        setFlipped([]);
        setLevel(1);
        setOldGame(0);
    };

    const buyGame = async () => {
        const data = await buyPlayGame();
        if (data.status == '999') {
            setIsPlaying(true);
            setGameOver(false);
            setLevelCompleted(false);
            setTimeLeft(120);
            playStartSound();

            toast.success('Purchased play successfully');

        } else {
            toast.error('Insufficient WKS coins. Please top up WKS coins.');

        }

    }

    const turnGame = async () => {
        const data = await setGameTurn();
        if (data.status == '999') {
            setIsPlaying(true);
            setGameOver(false);
            setLevelCompleted(false);
            setTimeLeft(120 - (level * 15));
            playStartSound();
            toast.success('You have successfully purchased a turn.');
        } else {
            toast.error('Not enough points please convert more points.');
        }
    }

    const toggleMusic = () => {
        setIsMusicPlaying(prev => !prev);
        if (isMusicPlaying) {
            if (sounds && sounds.startSound) {
                sounds.startSound.stop();
            }
            if (sounds && sounds.gameOverSound) {
                sounds.gameOverSound.stop();
            }
        } else {
            if (sounds && sounds.startSound) {
                sounds.startSound.play();
            }

        }
    };

    return (
        <>
            <div className="wapper-game-card-wolf">
                <div className="memory-game">
                    {!isPlaying && !gameOver && !levelCompleted ? (
                        <>
                            {playGame > 0 ? (
                                <><button className='play-button' onClick={handleStartGame}>Play <span>({playGame})</span></button></>

                            ) : (<><button className='play-button btn-buy-game' onClick={buyGame}>Buy more plays <span>( 1 WKS )</span></button></>)

                            }
                        </>

                    ) : gameOver ? (
                        <div className="game-over">
                            <h2>Game Over</h2>
                            <h4>Level {level}</h4>
                            <h5>{score}</h5>
                            <div>
                                <button className='button-end-game' onClick={restartGame}><FontAwesomeIcon icon={faXmark} /></button>
                                <button className='play-button' onClick={turnGame}>Play on <FontAwesomeIcon icon={faBolt} />50</button>
                            </div>
                        </div>
                    ) : levelCompleted ? (
                        <div className="level-complete">
                            <img src="images/win.png" />
                            <h2>Level {level}</h2>
                            <button onClick={handleNextLevel}>Continue</button>
                        </div>
                    ) : (
                        <>
                            <div className="score-board">
                                <h3>Point: {score}</h3>
                                <h3>Level: {level}</h3>
                                <div className="mute"><button className={isMusicPlaying ? 'btn-mute-game-card' : 'btn-mute-game-card active'} onClick={toggleMusic}> x </button></div>
                            </div>
                            <div className="time-bar">
                                <div className="time-bar-inner" style={{ width: `${(timeLeft / (120 - (level - 1) * 10)) * 100}%` }}></div>
                            </div>
                            <div className="grid">
                                {cards.map((card, index) => (
                                    <div
                                        key={index}
                                        className={`card ${flipped.includes(index) || matched.includes(index) ? 'flipped' : ''}`}
                                        onClick={() => handleFlip(index)}
                                    >
                                        <div className="inner">
                                            <div className="front cover"></div>
                                            <div className="back">
                                                {flipped.includes(index) || matched.includes(index) ? (
                                                    <img src={card.image} alt="card" />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default CardWolf;
