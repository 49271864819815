import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchAllUser } from '../../redux/slices/userSlice';
import NavAccount from './NavAccount';
import { getWallet, payWithdrawal } from '../../services/wallet';
import { convertDate, convertNumber } from '../../setup/fc';
import Transfer  from './Swap/Transfer';
import Deposit  from './Swap/Deposit';
import Withdraw  from './Swap/Withdraw';
import sounds, { stopAllSounds } from './Game/audioManager';
// import './Account.css';
function AccountWallet(props) {

    const [activeTab, setActiveTab] = useState(1);
    const [transaction, setTransaction] = useState([]);
    const [withdrawal, setWithdrawal] = useState(0);
    const [typePay, setTypePay] = useState(2);
    const [ratio, setRatio] = useState(10000);
    const [amount, setAmount] = useState(0);
    const [withdrawal1, setWithdrawal1] = useState(0);
    const [withdrawal2, setWithdrawal2] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    useEffect(() => {
        loadDataWallet();
    }, []);

    const loadDataWallet = async () => {
        stopAllSounds();
        const dataWallet = await getWallet();
        if (dataWallet) {
            setWithdrawal(dataWallet.point);
            localStorage.setItem('point', dataWallet.point);
            localStorage.setItem('pointCoin', dataWallet.pointCoin);
            setTransaction(dataWallet.listWallet);
            setWithdrawal1(dataWallet.withdrawal1);
            setWithdrawal2(dataWallet.withdrawal2);
            setRatio(dataWallet.ratio);
        }

    }

    const handleWithdrawal = async () => {
        if (isLoading == 1) {
            return true;
        }
        if (Number(amount) <= 0) {
            toast.error('Please enter withdrawal amount');
            return true;
        }
        if (Number(amount) > withdrawal) {
            setAmount(withdrawal);
            toast.error('You are allowed to withdraw up to ' + withdrawal);
            return true;
        }
        setIsLoading(1);
        const dataWallet = await payWithdrawal(amount, typePay);
        if (dataWallet && dataWallet.status == 999) {
            setWithdrawal(dataWallet.point);
            localStorage.setItem('point', dataWallet.point);
            localStorage.setItem('pointCoin', dataWallet.pointCoin);
            setTransaction(dataWallet.listWallet);
            toast.success('Your request will be processed within 24 hours');
        } else if (dataWallet && dataWallet.status == 888) {
            toast.error('The account does not have enough withdrawals');
        } else {
            toast.error('Withdrawal request failed');
        }

        setIsLoading(0);

    }
    const changeAmount = (e) => {

        let val = e.target.value;
        if (Number(val) > withdrawal) {
            setAmount(withdrawal);
            toast.error('You are allowed to withdraw up to ' + withdrawal);
            return true;
        }
        setAmount(val);

    }
    return (
        <>
            <div className='wapper-account'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-3'>
                            <NavAccount />
                        </div>
                        <div className='col-md-9'>
                            <main>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2">Swap</h1>
                                </div>
                                <div className="list-btn-swap">
                                    <button  className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>Transfer</button>
                                    <button   className={activeTab === 2 ? 'active' : ''} onClick={() => setActiveTab(2)}>Deposit</button>
                                    <button  className={activeTab === 3 ? 'active' : ''} onClick={() => setActiveTab(3)}>Withdraw</button>
                                </div>
                                <div>
                                    {activeTab === 1 && <Transfer /> }
                                    {activeTab === 2 && <Deposit />}
                                    {activeTab === 3 && <Withdraw /> }
                                </div>
                               
                                
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountWallet;