import cApi from '../setup/axios';

const loginUserServicer = async (userName, password) => {
    let userData = {userName, password };
    let rs = await cApi.post('api/v1/login', userData);
    return rs.data;
}

const registerUserServicer = async (fullName, email, userName, password,aff) => {
    let userData = { fullName, email, userName, password,aff };
    let rs = await cApi.post('api/v1/register', userData);
    return rs.data;
}

const RewardsMembers = async () => {
    let userData = {};
    let rs = await cApi.post('api/v1/rewardsmembers', userData);
    return rs.data;
}

const logoutUser = async () => {    
    let userData = {};
    let rs = await cApi.post('api/v1/logout', userData);
    return rs.data;
}
const ResetPassUser = async (userName) =>{
    let userData = { userName};
    let rs = await cApi.post('api/v1/resetpassword', userData);
    console.log(rs);
    return rs.data;
}
export {registerUserServicer,loginUserServicer,logoutUser,RewardsMembers,ResetPassUser}