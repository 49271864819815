import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import NavAccount from './NavAccount';
import { getProfile,postProfile,postProfilePass } from '../../services/profile';
import StartGame from '../Account/Game/StartGame';
import CardWolf from '../Account/Game/CardWolf';
// import './Account.css';
function AccountGame(props) {

    const [email, setEmail] = useState("");
    const [userLogin, setUserLogin] = useState("");

    const [oldPass, setoldPass] = useState("");
    const [newPass, setnewPass] = useState("");

    const [wallet, setWallet] = useState("");
    const [displayName, setdisplayName] = useState("");

    const isValidPass = () => {
        let check = 0;      

        if (!oldPass) {
            toast.error('Please enter your current password');
            check = 1;
        }

        if (!newPass) {
            toast.error('Please enter a new password');
           check = 1;
        }

        const validPasswordRegex = /^.{8,32}$/;
        if(!validPasswordRegex.test(newPass)){
            toast.error('Please enter a valid password (8 to 32 characters)');
            return false;
        }

        if(check == 1){
            return false;
        }

        return true;
    }
    const handleChangPass = async() => {
        if(isValidPass()){
            const profile = await postProfilePass(oldPass,newPass);

            if(profile.status == '999'){
                toast.success('Updated password successfully');
            }else{
                toast.error('Current password is incorrect');
            }
        }
    }

    const isValidProfile = () => {
        let check = 0;      

        if (!displayName) {
            toast.error('Full Name is required');
            check = 1;
        }

        if (!wallet) {
            toast.error('Wallet address is required');
           check = 1;
        }

        if(check == 1){
            return false;
        }

        return true;
    }
    const handleUpdateProfile = async() => {
       if(isValidProfile()){
            const profile = await postProfile(displayName,wallet);

            if(profile.status == '999'){
                toast.success('Success');
            }else{
                toast.error('Error');
            }
       }
    }
    
    useEffect( () => {
        loadProfile();
    },[])

    const loadProfile = async() => {
        const profile = await getProfile();       
        if(profile){
            setEmail(profile.email);
            setUserLogin(profile.userLogin);
            setWallet(profile.wallet);
            setdisplayName(profile.displayName);
           
        }
        
    }

   
    return (
        <>
            <div className='wapper-account'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-3'>
                            <NavAccount />
                        </div>
                        <div className='col-md-9'>
                            <main>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2">Game</h1>
                                </div>
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='personal-info'>
                                            <CardWolf />
                                        </div>
                                    </div>                                    
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountGame;