import React, { useEffect, useState } from 'react';
import copy from 'clipboard-copy';
import { loadWallet } from '../../services/wallet';
import NavAccount from './NavAccount';
import './Account.css';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import sounds, { stopAllSounds } from './Game/audioManager';
function Account(props) {
    
   
    useEffect(() => {
       loadData();
    }, []);

    const loadData = async() => {
        const dataWallet = await loadWallet();
        localStorage.setItem('point', dataWallet.point);
        localStorage.setItem('pointCoin', dataWallet.pointCoin);
        stopAllSounds();
    }

    const auth = JSON.parse(localStorage.getItem('auth'));
    const homeLink = window.location.origin + '/?aff=' + auth.user.id;


    const handleCopy = () => {
        copy(homeLink);
        toast.success("The link has been copied");
    }

    return (
        <>
            <div className='wapper-account'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-3'>
                            <NavAccount />
                        </div>
                        <div className='col-md-9'>
                            <main>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2">Dashboard</h1>
                                </div>
                                <div className='tabc'>
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className='group-text'>
                                                <label>Ref Link</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" placeholder="Ref Link" aria-describedby="button-addon2" readOnly={true} value={homeLink} />
                                                    <button className="btn btn-outline-secondary btn-copy" type="button" id="button-addon2" onClick={handleCopy}><FontAwesomeIcon icon={faCopy} /> Copy</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3>Referral Rewards Program</h3>                                           
                                            <p className="text-tb">- Refer a presale buyer: Earn 5% of their coins.</p>
                                            <p>Start referring today and enjoy these fantastic rewards!</p>
                                        </div>     
                                    </div>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Account;