import cApi from '../setup/axios';
import { useNavigate } from 'react-router-dom';

const getWallet = async () => {
    let userData = {};
    const rs = await cApi.post('api/v1/wallet', userData);   
    return rs.data;
}


const payWithdrawal = async (amount) => {
    let userData = { amount };
    let rs = await cApi.post('api/v1/paywithdrawal', userData);
    return rs.data;
}

const loadWallet = async () => {
    let userData = {};
    const rs = await cApi.post('api/v1/loadwallet', userData);   
    return rs.data;
}
const getWalletTransfer = async () => {
    let userData = {};
    const rs = await cApi.post('api/v1/wallettransfer', userData);   
    return rs.data;
}

const convertPoints = async (amount, typePay) => {
    let userData = { amount, typePay };
    let rs = await cApi.post('api/v1/convertpoints', userData);
    return rs.data;
}

export { getWallet, payWithdrawal,loadWallet,getWalletTransfer,convertPoints }