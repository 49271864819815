import React, { useEffect, useState } from 'react';
import { getDeposit } from '../../../services/deposit';
import { convertDate, convertNumber } from '../../../setup/fc';
import { toast } from 'react-toastify';
import Web3 from 'web3';
import bigDecimal from "js-big-decimal";
import { senCoinToUser, checkApproveFFA,submitApproveAff } from "../../../services/smartContract";
import { abi_ffa, contractFFa } from '../../../setup/FFA.js';
function Deposit() {
    const bigUnit = new bigDecimal("1000000000000000000");
    const [transaction, setTransaction] = useState([]);
    const [withdrawal, setWithdrawal] = useState(0);
    const [typePay, setTypePay] = useState(2);
    const [ratio, setRatio] = useState(10000);
    const [amount, setAmount] = useState(0);
    const [affBalance, setAffBalance] = useState(0);
    const [approveAFF, setApproveAFF] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    useEffect(() => {
        loadDataWallet();
        fetchData();
    }, []);

    const fetchData = async () => {
      
        if (typeof window.ethereum !== 'undefined') {
            try {
                const web3 = new Web3(window.ethereum);
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const accounts = await web3.eth.getAccounts();
                const userAddress = accounts[0];
                const balance = await web3.eth.getBalance(accounts[0]);
                let bnbBalance = Number(new bigDecimal(balance).divide(bigUnit).value);

                const contract = new web3.eth.Contract(
                    abi_ffa,
                    contractFFa
                );
                let getAmountToken = await contract.methods.balanceOf(accounts[0]).call();
                let usdtBalance = Number(new bigDecimal(getAmountToken).divide(bigUnit).value);
                setAffBalance(usdtBalance);
                const checkApp = await checkApproveFFA();               
                setApproveAFF(checkApp.stt);


            } catch (error) {
                console.error('User denied account access');
            }
        } else {
            console.error('Metamask is not installed');
        }
    }
    const loadDataWallet = async () => {
        const dataDeposit = await getDeposit();
        if (dataDeposit) {
            setWithdrawal(dataDeposit.point);
            localStorage.setItem('point', dataDeposit.point);
            localStorage.setItem('pointCoin', dataDeposit.pointCoin);
            setTransaction(dataDeposit.list);

        }

    }

    const handleWithdrawal = async () => {
        if (isLoading == 1) {
            return true;
        }
        if (Number(amount) <= 0) {
            toast.error('Please enter withdrawal amount');
            return true;
        }
        if (Number(amount) > affBalance) {
            setAmount(affBalance);
            toast.error('You are allowed to withdraw up to ' + affBalance);
            return true;
        }
        setIsLoading(1);
        const auth = JSON.parse(localStorage.getItem('auth'));
        let data = await senCoinToUser(amount,auth.user.id);
        if (data) {
            toast.success('The system is processing');
        } 
        setIsLoading(0);

    }

    const fcApproveAFF = async () => {
        let data = await submitApproveAff(affBalance);
        if (!data) {          
            // toast.error("Error");
            setIsLoading(0);
        } else {
            setApproveAFF(1);
            setIsLoading(0);

        }
    }

    const changeAmount = (e) => {

        let val = e.target.value;
        if (Number(val) > affBalance) {
            setAmount(affBalance);
            toast.error('You are allowed to withdraw up to ' + affBalance);
            return true;
        }
        setAmount(val);

    }
    return (
        <>
            <div className="row">
                <div className='col-md-12'>
                    <div className='withdraw-money tabc'>
                        <h3>Deposit</h3>
                        <div className="mb-3">
                            <label htmlFor="ipwallet" className="form-label">Number of WKS ({affBalance})</label>
                            <input type="number" className="form-control" id="ipwallet" placeholder="Withdrawal amount" value={amount} onChange={(e) => changeAmount(e)} />
                        </div>
                        {
                            isLoading == '0' ? (
                                <>
                                { approveAFF == 0 ? (<><button className="btn btn-primary py-2" type="button" onClick={() => fcApproveAFF()}>Approve</button></>) 
                                : (<><button className="btn btn-primary py-2" type="button" onClick={() => handleWithdrawal()}>Send coins</button></>)}
                                    
                                </>
                            ) : (<>
                                <button className="btn btn-primary py-2" type="button">Processing</button>
                            </>)

                        }

                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='tabc'>
                        <table className='table tablec'>
                            <thead>
                                <tr>
                                    <th>Quantity</th>                                    
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    transaction && transaction.length !== undefined ? (
                                        transaction.map((item, index) => (
                                            <>
                                                <tr>

                                                    <td>{convertNumber(item.coin)}</td>                                                   
                                                    <td>{convertDate(item.createdAt)}</td>
                                                </tr>
                                            </>
                                        ))
                                    ) : (<></>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Deposit;
