import React from 'react';

function Tokenomics(props) {
    return (
        <>
            <div id="sc_tokenomics">
                <div className='container'>
                    <div className='row'>   
                        <div className='col-md-12 px-5 text-center'><h2>Tokenomics</h2>   </div>                     
                        <div className='col-md-6 px-5'>
                            <div className='tokenomics-img'>
                                <img src="images/chart.png" />
                            </div>
                        </div>
                        <div className='col-md-6 px-5'>
                            <div className='tokenomics-info'>                                                            
                                <p>Wolf Kingdoms (WKS) coin is designed with a clear and strategic token distribution to support long-term sustainability and growth of our ecosystem. The total supply of WKS tokens is capped at 1 billion (1,000,000,000 WKS), meticulously allocated to ensure the development, incentivization, and liquidity of the platform.</p>
                                <ul>
                                    <li className='tokenomics1'>Liquidity (25%)</li>
                                    <li className='tokenomics3'>Community Rewards (20%)</li>
                                    <li className='tokenomics2'>Staking (20%)</li>                                   
                                    <li className='tokenomics4'>Presale (15%)</li>
                                    <li className='tokenomics5'>Team (10%)</li>
                                    <li className='tokenomics6'>Marketing (10%)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tokenomics;