import React from 'react';

function Team(props) {
    return (
        <>
            <div id="sc_team">
                <div id="sc_team_op">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <h2 className='title-team'><span>Our team</span></h2>
                            </div>
                            <div className="col-md-4">
                                <div className="item-team">
                                    <div className="item-team-bg">
                                    <img src="images/te1.jpg" className='avatar-team' />
                                    <h4>Michal Matejcek</h4>
                                    <span>CEO</span>
                                    <div>
                                        I've been in project management and business development for years. I specialize in setting technical vision and leading blockchain development, minimizing risks and solving technical challenges.
                                    </div>
                                    </div>
                                </div>
                            </div>
                     

                        <div className="col-md-4">
                            <div className="item-team item-team2">
                                <div className="item-team-bg">
                                    <img src="images/te2.jpg" className='avatar-team' />
                                    <h4>Natasha Maycock</h4>
                                    <span>Marketing Manager</span>
                                    <div>
                                        I am a seasoned business development and marketing professional with expertise in strategy, budget management, digital marketing, CRM, and client care
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="item-team">
                                <div className="item-team-bg">
                                    <img src="images/te3.jpg" className='avatar-team' />
                                    <h4>Neil Palethorpe</h4>
                                    <span>Blockchain Developer</span>
                                    <div>
                                        Experienced programmer and designer in Leicestershire, UK, specializing in React Components, AWS API, and cloud architecture with 12+ years in retail, fintech, and blockchain.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </>
    );
}

export default Team;
