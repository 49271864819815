import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'

export const fetchAllUser = createAsyncThunk(
    'users/fetchAllUser',
    async () => {
        let data = [];
      return data
    }
  )

const initialState = {
  user: [],
  isAuthenticated: false,
  token: '',
  isError: false,
  level:0
}

export const AuthSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    setAuth: (state,action) => {     
        const { user, token,level } = action.payload;
        state.user = user;
        state.isAuthenticated = true;
        state.token = token;
        state. isError = false;
        state.level = level;
    },
    clearAuth: (state) => {
        state.user = [];
        state.isAuthenticated = false;
        state.token = '';
        state. isError = false;
        state.level = 0;
    },
    logoutAuth: (state) => {
      state.user = [];
      state.isAuthenticated = false;
      state.token = '';
      state.isError = false;
      state.level = 0;
  }       
  },
  extraReducers: (builder) => {
    // builder
    // .addCase(fetchAllUser.pending,(state,action) => {
    //     state.isLoading = true;
    //     state.isError = false;
    // })
    // .addCase(fetchAllUser.fulfilled,(state,action) => {
    //     state.listUser = action.payload;
    // })   
    // .addCase(fetchAllUser.rejected,(state,action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    // })
  }
})
export const { setAuth, clearAuth,logoutAuth } = AuthSlice.actions;
export default AuthSlice.reducer