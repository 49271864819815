import React, { useEffect, useState } from 'react';
import copy from 'clipboard-copy';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchAllUser } from '../../redux/slices/userSlice';
import NavAccount from './NavAccount';
// import './Account.css';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { getAffiliate } from '../../services/affiliate.js';
import { convertDate, convertNumber, viewAddress } from '../../setup/fc';
import sounds, { stopAllSounds } from './Game/audioManager';

function AccountAffiliate(props) {
    const [transaction, setTransaction] = useState([]);
    // const count = useSelector((state) => state.vinh.value);
    // const dispatch = useDispatch();
    const [totalPoints, setTotalPoints] = useState(0);
    const [totalUsdt, setTotalUsdt] = useState(0);
    const [totalBNB, setTotalBNB] = useState(0);

    useEffect(() => {
        loadDataAffiliate();
    }, []);

    const loadDataAffiliate = async () => {
        stopAllSounds();
        const dataAffiliate = await getAffiliate();
        if (dataAffiliate) {
            setTransaction(dataAffiliate.listAffiliate);

            if (dataAffiliate.totalBNB.totalService !== undefined && dataAffiliate.totalBNB.totalService !== null) {
                setTotalBNB(dataAffiliate.totalBNB.totalService);
            }

            if (dataAffiliate.totalPoints.totalPoints !== undefined && dataAffiliate.totalPoints.totalPoints !== null) {
                setTotalPoints(dataAffiliate.totalPoints.totalPoints);
            }

            if (dataAffiliate.totalUsdt.totalService !== undefined && dataAffiliate.totalUsdt.totalService !== null) {
                setTotalUsdt(dataAffiliate.totalUsdt.totalService);
            }

        }

    }
    // const listUser = useSelector((state) => state.userList.listUser);
    const auth = JSON.parse(localStorage.getItem('auth'));
    const homeLink = window.location.origin + '/?aff=' + auth.user.id;

    const handleCopy = () => {
        copy(homeLink);
        toast.success("The link has been copied");
    }
    return (
        <>
            <div className='wapper-account'>
                <div className="container">
                    <div className="row">
                        <div className='col-md-3'>
                            <NavAccount />
                        </div>
                        <div className='col-md-9'>
                            <main>
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                                    <h1 className="h2">Reward</h1>
                                </div>
                                <div className='tabc'>
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className='group-text'>
                                                <label>Ref Link</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control" placeholder="Ref Link" aria-describedby="button-addon2" readOnly={true} value={homeLink} />
                                                    <button className="btn btn-outline-secondary btn-copy" type="button" id="button-addon2" onClick={handleCopy}><FontAwesomeIcon icon={faCopy} /> Copy</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <h3>Referral Rewards Program</h3>
                                            <p className="text-tb">- Refer a presale buyer: Earn 5% of their coins.</p>
                                            <p>Start referring today and enjoy these fantastic rewards!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='tabc'>
                                    <h3>Your sales information</h3>
                                    <hr></hr>
                                    <div className="row">
                                        <div className='col-md-4'>
                                            <div className='item-view-aff'>
                                                <h3>{totalUsdt}</h3>
                                                <h4>Total USDT</h4>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='item-view-aff'>
                                                <h3>{totalBNB}</h3>
                                                <h4>Total BNB</h4>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='item-view-aff'>
                                                <h3>{totalPoints} WKS</h3>
                                                <h4>Rose Tone</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='tabc'>
                                    <table className='table tablec'>
                                        <thead>
                                            <tr>
                                                <th>Address</th>
                                                <th>Rose</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                transaction && transaction.length !== undefined ? (
                                                    transaction.map((item, index) => (
                                                        <>
                                                            <tr>
                                                                <td>{viewAddress(item.wallet)}</td>

                                                                <td><strong className='text-warning'>{convertNumber(item.point)} WKS</strong></td>
                                                                <td>{convertDate(item.createdAt)}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                ) : (<></>)


                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountAffiliate;