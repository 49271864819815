import React, { useEffect } from 'react';

function convertDate(dateString) {

    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds} `;
    return formattedDate;
   
}

function convertNumber(numberString) {

  const number = parseFloat(numberString);

  const formattedNumber = number.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 5
  });
   return formattedNumber;
}

const viewAddress = (address) => {
    if (!address) return ''; 

    let startLength = 6;
    let endLength = 4;
    const trimmedAddress =
      address.substring(0, startLength) + '...' + address.slice(-endLength);

    return trimmedAddress;
  };

export { convertDate,convertNumber,viewAddress };