import React from 'react';
import About from './About';
import Presale from './Presale';
import Roadmap from './Roadmap';
import Banner from './Banner';
import Tokenomics from './Tokenomics';
import Partners from './Partners';
import Team from './Team';
import Footer from '../Layout/Footer';
import SaleRound from './SaleRound';
import Member from './Member';
import Rankings from './Rankings';
import Contact from './Contact';
import './home.css';
function Home(props) {
  return (
    <>

      <div className='page-home'>
        <Banner />
        <div className='wapper-bg-main'>
          <Presale />
          <About />
          <Rankings />         
          <Tokenomics />
          <Roadmap />
          <Team />
          <Partners />
        </div>
        <Contact />

      </div>

    </>
  );
}

export default Home;