import React, { useState, useEffect } from 'react';
import Head from './componets/Layout/Head';
import Footer from './componets/Layout/Footer';
import _ from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import AppRoutes from './routes/AppRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../src/redux/slices/authSlice';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
function App(props) {

  const dispatch = useDispatch();

  useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    const aff = params.get('aff');
    if (aff) {
      localStorage.setItem('aff', aff);
    }
    const auth = JSON.parse(localStorage.getItem('auth'));
    if (auth) {
      dispatch(setAuth({ user: auth.user, token: auth.token, level: auth.level, }));
    }
  }, [dispatch]);

  return (
    <>
      <Router>
        <Head />
        <AppRoutes />
        <Footer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Router>
    </>

  );
}


export default App;
