import cApi from '../setup/axios';
import { useNavigate } from 'react-router-dom';



const registerUserServicer = async (fullName, email, userName, password) => {
    let userData = { fullName, email, userName, password };
    let rs = await cApi.post('api/v1/register', userData);
    return rs.data;
}
const getProfile = async () => {
    let userData = {};
    const rs = await cApi.post('api/v1/profile', userData);   
    return rs.data;
}

const postProfile = async (displayName, wallet) => {
    let userData = { displayName, wallet };
    let rs = await cApi.post('api/v1/updateprofile', userData);
    return rs.data;
}
const postProfilePass = async (oldPass, newPass) => {
    let userData = { oldPass, newPass };
    let rs = await cApi.post('api/v1/updatepassword', userData);
    return rs.data;
}


export { getProfile, postProfile,postProfilePass }