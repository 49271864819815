import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerPadding: '30px',
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
            }
        }
    ]
};

function Partners(props) {
    return (
        <>
            <div id="sc_partners">
                <div id="bg_partners">
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="box-partners ">
                                <div className="box-partners-main">
                                    <div className="box-partners-content">
                                        <h2>WKS Partners</h2>

                                        <Slider {...settings}>
                                            <div className="item">
                                                <a href="https://coinmarketcap.com/" target='_blank'>
                                                    <img src="images/partner3.png" />
                                                </a>
                                            </div>
                                            <div className="item">
                                                <a href="https://www.dextools.io/" target='_blank'>
                                                    <img src="images/partner4.png" />
                                                </a>
                                            </div>
                                            <div className="item">
                                                <a href="https://binance.com/" target='_blank'>
                                                    <img src="images/partner1.png" />
                                                </a>
                                            </div>
                                            <div className="item">
                                                <a href="https://avedex.cc/" target='_blank'>
                                                    <img src="images/partner5.png" />
                                                </a>
                                            </div>
                                            <div className="item">
                                                <a href="https://www.mexc.com/" target='_blank'>
                                                    <img src="images/partner6.png" />
                                                </a>
                                            </div>
                                            <div className="item">
                                                <a href="https://pancakeswap.finance/" target='_blank'>
                                                    <img src="images/partner2.png" />
                                                </a>
                                            </div>
                                            <div className="item">
                                                <a href="https://www.dexview.com/" target='_blank'>
                                                    <img src="images/partner7.png" />
                                                </a>
                                            </div>
                                            <div className="item">
                                                <a href="https://www.certik.com/" target='_blank'>
                                                    <img src="images/partner8.png" />
                                                </a>
                                            </div>
                                        </Slider>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

        </>
    );
}

export default Partners;