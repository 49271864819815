import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";
import Login from '../componets/Login/Login';
import Register from '../componets/Register/Register';
import NotFound from '../componets/Layout/NotFound';
import Home from '../componets/Home/Home';
import Resetpass from '../componets/Login/Resetpass';
function VisitorRouter(props) {

    return (
        <>
            <Routes>  
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />      
                <Route path="/resetpassword" element={<Resetpass />} />          
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
            </Routes>

        </>
    );
}

export default VisitorRouter;