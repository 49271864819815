import { createSlice } from '@reduxjs/toolkit';

const pointSlice = createSlice({
    name: 'point',
    initialState: 0, // Giá trị mặc định của điểm
    reducers: {
        setPoint(state, action) {
            return action.payload; // Cập nhật điểm
        },
    },
});

export const { setPoint } = pointSlice.actions;
export default pointSlice.reducer;