import React, { useState,useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {ResetPassUser} from '../../services/userService';
import { useDispatch, useSelector } from 'react-redux';
import {setAuth} from '../../redux/slices/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
function Resetpass(props) {
    const dispatch = useDispatch(); 
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    let navigate = useNavigate();
    const btnLinkLogin = () => {
        navigate('/login');
    }

    const handleForgotPassword = () => {
        navigate('/resetpassword');
    }

    
    const isValidInputs = () => {
        let check = 0;      

        if (!userName) {
            toast.error('Username is required');
            check = 1;
        }       

        if(check == 1){
            return false;
        }
       
        return true;
    }
    const handleLogin = async() => {        
        if (isValidInputs()) {    
            let data = await ResetPassUser(userName);                   
           
            if(data.status == '999'){
                toast.success(data.msg);
                
                // navigate('/account');              
                return false;
            }else{
                toast.error(data.msg);
                return false;
            }
        }
    }

    return (
        <>
            <div className='login-container'>
                <div className='container'>
                    <div className='row'>
                        <div className="form-signin w-100 m-auto">
                            <form>
                                <h1 className="h3 mb-3 fw-normal">Forgot Your Password?</h1>
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="UserName" placeholder="Username" value={userName} onChange={(event) => setUserName(event.target.value)} />
                                    <label htmlFor="UserName">Username</label>
                                </div>                                                                                                           
                                <button className="btn btn-primary w-100 py-2 btn-login1" type="button" onClick={() => handleLogin()}>Reset Password</button>
                                <button className="btn w-100 py-2 btn-login2" type="button" onClick={() => btnLinkLogin()}>Login</button>
                                                          
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Resetpass;