import React from 'react';

function Roadmap(props) {
    return (
        <>
            <div id="sc_roadmap">
                <div id="bg_roadmap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className='text-center title-h'>Road Map</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <div className="item-timeline item-timeline1">
                                    <div href="#" className="timeline-content">
                                        <h3 className="title"><span>Phase 1</span></h3>
                                        <div className="description">
                                            <ul>
                                                <li>Finalize the development of the main game and initiate pre-launch marketing campaigns.</li>
                                                <li>Officially launch the game and begin the presale phase.</li>
                                                <li>Expand the initial player and investor community, organize events and promotional programs to enhance engagement.</li>
                                                <li>Complete the presale and list WKS coins on major exchanges.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item-timeline item-timeline2">
                                    <div href="#" className="timeline-content">
                                        <h3 className="title"><span>Phase 2</span></h3>
                                        <div className="description">
                                            <ul>
                                                <li>Integrate new features into the game, expand the game ecosystem with mini-games and special events.</li>
                                                <li>Partner with strategic players in the gaming and blockchain industries, enhance marketing campaigns to expand the global user community.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="item-timeline item-timeline3">
                                    <div href="#" className="timeline-content">
                                        <h3 className="title"><span>Phase 3</span></h3>
                                        <div className="description">
                                            <ul>
                                                <li>Create more games within the WKS ecosystem, enhance user experience, and maintain platform stability.</li>
                                                <li>Grow the global community, stabilize the value of WKS coins, and continuously enhance and develop the platform according to user feedback.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Roadmap;