import { configureStore } from '@reduxjs/toolkit';
import counterSlice from '../redux/slices/counterSlice';
import authSlice from '../redux/slices/authSlice';
import pointSlice from '../redux/slices/pointSlice';
import pointCoinSlice from '../redux/slices/pointCoinSlice';
const stores = configureStore({
    reducer: {       
        auth:authSlice,
        point:pointSlice,
        pointCoin:pointCoinSlice
    },
  })
export default stores;