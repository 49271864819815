import React, { useState,useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
function NotFound(props) {
    let navigate = useNavigate();
    const toHome = () => {
        navigate('/');
    }

    useEffect(() => {
        toHome();
    },[])
    return (
        <div>
            404
        </div>
    );
}

export default NotFound;