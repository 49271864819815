import { Howl } from 'howler';

// Tạo các instance Howl cho từng âm thanh
const sounds = {
  startSound: new Howl({
    src: ['/media/musicGame.mp3'],
    autoplay: false,
    loop: true,
    volume: 1.0,
  }),
  gameOverSound: new Howl({
    src: ['/media/game_over.webm'],
    autoplay: false,
    volume: 1.0,
  }),
  btnOverSound: new Howl({
    src: ['/media/button.webm'],
    autoplay: false,
    volume: 1.0,
  }),
  coinsSound: new Howl({
    src: ['/media/coins.webm'],
    autoplay: false,
    volume: 1.0,
  }),
  winSound: new Howl({
    src: ['/media/game_won.webm'],
    autoplay: false,
    volume: 1.0,
  }),
};

export const stopAllSounds = () => {
  Object.values(sounds).forEach(sound => {
    sound.stop();
  });
};

export default sounds;
