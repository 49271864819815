import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import VisitorRoutes from '../routes/VisitorRoutes';
import AdminRoutes from '../routes/AdminRoutes';
import UserRoutes from '../routes/UserRoutes';

function AppRoutes(props) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.auth.isAuthenticated);  
    const levelAuth = useSelector((state) => state.auth.level);
     if(isAuth && isAuth == true){      
        if(levelAuth == '9'){           
            return ( <AdminRoutes/>);
        }else if(levelAuth == '1'){           
            return ( <UserRoutes/>);
        }

     }else{
        return ( <VisitorRoutes/>);
     }   
    
}

export default AppRoutes;