import cApi from '../setup/axios';
import { useNavigate } from 'react-router-dom';


const getPlaygame = async () => {
    let userData = {};
    const rs = await cApi.post('api/v1/playgame', userData);   
    return rs.data;
}

const setGamePoint = async (point, level) => {
    let userData = { point, level };
    let rs = await cApi.post('api/v1/setgamepoint', userData);
    return rs.data;
}
const setGamePointOld = async (point, level,gamepoint) => {
    let userData = { point, level, gamepoint };
    let rs = await cApi.post('api/v1/setgamepointold', userData);
    return rs.data;
}

const getTopGame = async () => {
    let userData = {};
    let rs = await cApi.get('/topgame', userData);
    return rs.data;
}
const postProfilePass = async (oldPass, newPass) => {
    let userData = { oldPass, newPass };
    let rs = await cApi.post('api/v1/updatepassword', userData);
    return rs.data;
}

const userStartGame = async () => {
    let userData = {};
    let rs = await cApi.post('api/v1/userstartgame', userData);
    return rs.data;
}

const setGameTurn = async () => {
    let userData = {};
    let rs = await cApi.post('api/v1/setgameturn', userData);
    return rs.data;
}


const buyPlayGame = async () => {
    let userData = {};
    let rs = await cApi.post('api/v1/buyplaygame', userData);
    return rs.data;
}



export { getPlaygame,setGamePoint,getTopGame,userStartGame,setGameTurn,buyPlayGame,setGamePointOld }