// src/redux/slices/pointCoinSlice.js
import { createSlice } from '@reduxjs/toolkit';

const pointCoinSlice = createSlice({
    name: 'pointCoin',
    initialState: 0, // Giá trị mặc định của điểm coin
    reducers: {
        setPointCoin(state, action) {
            return action.payload; // Cập nhật điểm coin
        },
    },
});

export const { setPointCoin } = pointCoinSlice.actions;
export default pointCoinSlice.reducer;
