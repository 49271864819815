import React from 'react';
import { useNavigate } from 'react-router-dom';

function Banner(props) {
    let navigate = useNavigate();
    const registerNow = () => {
        navigate('/register');
    }
    return (
        <>
            <div id="sc_banner"  >
            <img src="/images/banner.webp" alt="Support" />    
            </div>
        </>
    );
}

export default Banner;