import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

function Contact(props) {
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [contactMsg, setContactMsg] = useState('');
    const [isSent, setIsSent] = useState(false);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSend = () => {
        if (!email || !subject || !message) {
            setContactMsg('Please fill in all fields.');
        } else if (!validateEmail(email)) {
            setContactMsg('Please enter a valid email address.');
        } else {
            setContactMsg('Your message has been sent.');
            setIsSent(true);
            // Clear fields after successful submission (if needed)
            setEmail('');
            setSubject('');
            setMessage('');
        }
    };

    return (
        <>
            <div id="sc_contact">
                <div id="bg_contact">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-left">
                                <h2>CONTACT US</h2>
                                <p>We are always open to your feedback and available to answer any questions you might have. Please provide your information and the details of your request in the form below, and we will respond to you promptly.</p>
                            </div>
                            <div className="col-lg-12 text-left"></div>
                            <div className="col-lg-6 text-left">
                                <div className="box-fr-contact">
                                    <div className="fr-contact">
                                        <input
                                            type='text'
                                            placeholder='Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            disabled={isSent}
                                        />
                                    </div>
                                    <div className="fr-contact">
                                        <input
                                            type='text'
                                            placeholder='Subject'
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            disabled={isSent}
                                        />
                                    </div>
                                    <div className="fr-contact">
                                        <textarea
                                            placeholder='Message'
                                            rows="5"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            disabled={isSent}
                                        ></textarea>
                                    </div>
                                    <div className="contact-msg">
                                        {contactMsg && <p>{contactMsg}</p>}
                                    </div>
                                    {!isSent && (
                                        <button type='button' className="btn-contact" onClick={handleSend}>Send</button>
                                    )}
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;